import React from 'react';
import BlogArticle from '../../../components/common/BlogArticle';
import SEO from '../../../components/SEO';

import { useScrollToTop } from '../../../hooks/useScrollToTop';
import blogImage from '../../../assets/img/blog/blog-5.jpeg';

import './BlogArticlePage.scss';

interface BlogData {
    id: number;
    title?: string;
    subtitle?: string;
    text: string;
    type: string;
    subtype: string;
}

interface BlogItems extends Array<BlogData> {}

const BlogArticlePage = () => {
    const contentData = [
        {
            id: 0,
            text: 'The Challenges of Scaling Engineering Teams and Options',
            type: 'margin',
            subtype: 'blog-content',
        },
        {
            id: 1,
            text: 'Understanding Managed Services and Their Benefits',
            type: 'margin',
            subtype: 'blog-content',
        },
        {
            id: 2,
            text: 'When Should Companies Consider Managed Services?',
            type: 'margin',
            subtype: 'blog-content',
        },
        {
            id: 3,
            text: 'Introducing Blackbird As Your Trusted Co-innovation Partner',
            type: 'margin',
            subtype: 'blog-content',
        },
        {
            id: 4,
            text: 'Conclusion',
            type: 'margin',
            subtype: 'blog-content',
        },
    ];
    const blogData: BlogItems = [
        {
            id: 0,
            title: 'The Challenges of Scaling Engineering Teams and Options',
            text:
                'Steering  your  company  through  the  rapid  growth  phase  is  no  easy  task.  As  your  product gains  traction  and  demand  skyrockets,  your  existing  development  team  might  find  it  challenging to  keep  up  with  the  pace.  In  such  scenarios,  leaders  explore  various  options  to  address  this challenge and ensure continued success. Among the common strategies are the following:',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 1,
            text:
                'Stretching Existing Developers Across Multiple Tracks This   approach   involves  pushing  the  current  development  team  to  work  on  multiple  projects simultaneously.  While  it  may  seem  like  a  viable  short-term  solution,  it  can  lead  to  burnout  and decreased productivity, potentially compromising the quality of deliverables.',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 2,
            text:
                "Stretching Existing Developers and Hiring More In-house Engineers This  option  combines  stretching  the  team's  capacity  with  recruiting  of  new  in-house  engineers. While  it  may  alleviate  some  pressure,  hiring  and  onboarding  new  talent  can  be  time-consuming and expensive",
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 3,
            text:
                'Hiring a Co-innovation Partner for Managed Services The  third  option  involves  engaging  a  trusted  managed  service  provider  like  Blackbird.  By  doing so,  companies  can  efficiently  scale  their  development  capabilities  without  overburdening  their existing  teams.  This  allows  them  to  focus  on  their  core  high-priority  initiatives  while  entrusting other important projects to a reliable partner.',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 4,
            title: 'Understanding Managed Services and Their Benefits',
            text:
                "Managed  services  present  a  compelling  solution  for  companies  looking  to  optimize  their development   efforts   while   maintaining   high   standards.   Let's   delve   into   the   key   benefits   of managed services compared to the traditional time and material model: Dedicated Team: Your Extended Team of Experts",
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 4,
            subtitle: 'Dedicated Team: Your Extended Team of Experts',
            text:
                'With  managed  services,  you  gain  access  to  a  dedicated  team  of  skilled  professionals  committed to   your   projects.   This   team   functions   as   an   extension   of   your   in-house   staff,   seamlessly integrating   with   your   existing   processes   and   culture.   Their   specialized   expertise   ensures efficient project execution and high-quality outcomes',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 4,
            subtitle: 'Monthly Retainer: Predictable Costs, Optimal Results',
            text:
                'Managed  services  operate  on  a  monthly  retainer  basis,  offering  predictable  costs  and  greater financial  control.  This  pricing  model  allows  you  to  plan  and  budget  effectively,  eliminating  the uncertainty associated with time and material arrangements',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 4,
            subtitle: 'Seamless Integration: Harmonious Collaboration with Your Core Team',
            text:
                "Managed  service  providers  like  Blackbird  prioritize  smooth  collaboration.  They  work  in  tandem with  your  core  team,  maintaining  open  communication  channels  and  fostering  a  harmonious working   relationship.   This   integration   ensures   alignment   with   your   company's   vision   and objectives",
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 4,
            subtitle: 'Proactive Management: Addressing Challenges Before They Arise',
            text:
                'Managed   service  providers  are  proactive  in  identifying  and  addressing  potential  challenges. Their  experience  and  foresight  enable  them  to  mitigate  risks,  prevent  bottlenecks,  and  resolve issues before they escalate, ensuring project progress remains on track',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 4,
            subtitle: 'Focus on Deliverables: Accelerating Project Completion',
            text:
                'With  a  dedicated  focus  on  deliverables,  managed  services  expedite  project  completion. Their  efficiency  and  streamlined  processes  lead  to  faster  time-to-market,  giving  your  company  a competitive edge',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 4,
            subtitle: 'Expertise and Best Practices: Leveraging Industry-leading Skills',
            text:
                'Managed  service  providers  bring  a  wealth  of  expertise  and  industry  best  practices  to  the  table. They  stay  up-to-date  with  the  latest  technological  advancements  and  implement  cutting-edge solutions, enhancing the overall quality of your projects.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            title: 'When Should Companies Consider Managed Services?',
            text:
                "A. Rapid  Adoption  and  Stretched  Resources:  If  your  company's  product  is  experiencing swift  adoption,  your  existing  team  might  be  stretched  thin,  struggling  to  keep  up  with  the  growing demand.   Managed   services   can   quickly   supplement   your   team,   allowing  them  to  focus  on crucial tasks.",
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            text:
                'B. Navigating  Through  High-priority  Initiatives:  When  high-priority  initiatives  demand  the full  attention  of  your  core  team,  managed  services  can  take  care  of  other  essential  projects without compromising on quality.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            text:
                'C. Tier-2   Projects:   While   core   high-priority   initiatives   are   best   kept   in-house,   tier-2 projects  present  an  excellent  opportunity  for  managed  services.  Blackbird  excels  in  this  space, ensuring   that   your   important   but   less   critical   projects   are   handled   with   utmost   care   and expertise.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            text:
                'D. Ensuring Cost-effectiveness: Comparing Managed Services to Other Options Considering  the  financial  aspect,  managed  services  often  offer  a  more  cost-effective  solution than stretching existing resources or hiring additional in-house engineers',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            title: 'Introducing Blackbird As Your Trusted Co-innovation Partner',
            text:
                'Blackbird  has  a  solid  track  record  of  collaborating  with  companies  as  their  co-innovation partner.   Our   expertise   in   managed   services   has   helped   businesses   overcome   challenges, optimize  their  development  efforts,  and  achieve  success.  As  a  trusted  partner,  Blackbird  takes ownership  and  responsibility  for  a  range  of  processes  and  functions,  allowing  your  team  to  focus on  its  core  strengths.  Our  seamless  integration  ensures  enhanced  efficiency  and  accelerated project  timelines.  We  understand  the  significance  of  trust  in  a  partnership;  our  team  is  deeply committed to delivering exceptional results.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            title: 'Conclusion',
            text:
                'Managed   services   have   emerged   as   a  powerful  solution  for  scaling  teams,  meeting demands,   and   driving   innovation.   By   leveraging   the   benefits   of   managed   services   like   a dedicated   team,   predictable   costs,   seamless   integration,   proactive   management,   focus   on deliverables,  expertise,  and  best  practices,  companies  can  navigate  growth  challenges  more efficiently and effectively',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            text:
                'At   Blackbird,   we   are   passionate   about   co-innovating   with   our   clients,   helping   them achieve  their  goals,  and  taking  their  success  to  new  heights.  If  your  company  is  facing  the challenges  of  scaling  its  engineering  capabilities,  we  invite  you  to  explore  the  possibilities  of partnering with us. Together, we can build a future of innovation and excellence.',
            type: 'mb',
            subtype: 'blog',
        },
    ];

    useScrollToTop();

    return (
        <div>
            <SEO
                pageTitle="Blackbird Lab - Blog"
                pageDescription="Unlocking the Power of Managed Services: A Smart Choice for Scaling Engineering Team"
                pageImage="https://blackbird-image-cdn.s3.eu-central-1.amazonaws.com/blog/articles/unlocking-the-power-of-managed-service.png"
            />
            <BlogArticle
                title="Unlocking the Power of Managed Services: A Smart Choice for Scaling Engineering Team"
                image={blogImage}
                author="Yurii Drozd"
                date="August 30, 2023"
                blogData={blogData}
                contentData={contentData}
            />
        </div>
    );
};

export default BlogArticlePage;
